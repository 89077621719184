<template>
  <b-container>
    <b-row>
      <b-col cols=6
      offset=3>
        <h1>Sign in</h1>
        <router-link to="/login"
        class="text-success mb-4">
          Have an account ?
        </router-link>
        <b-form
        @submit.stop.prevent="register">
          <b-form-invalid-feedback :state="stateLogin">
            <div v-for="([ key, error]) in errors"
            class="text-danger"
            :key="key">
              <div v-for="item in error"
              :key="item">
                {{ key }} {{ item }}
              </div>
            </div>
          </b-form-invalid-feedback>
          <b-form-input 
          required 
          type="email"
          name="email"
          autocomplete="on"
          :disabled="isLoading"
          v-model="email"
          placeholder="Email" 
          class="mb-3"/>
          <b-form-input 
          required 
          type="text"
          name="username"
          :disabled="isLoading"
          v-model="username"
          placeholder="Username" 
          class="mb-3"/>
          <b-form-input 
          required
          type="password"
          name="password"
          autocomplete="on"
          :disabled="isLoading"
          v-model="password"
          placeholder="Password" 
          class="mb-3"/>
          <b-button 
          type="submit"
          :disabled="isLoading"
          :class="isLoading ? 'btn-disabled' : 'btn-success'">
            Sign in
          </b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import store from '@/store'

export default {
  metaInfo () {
    return {
      title: `Register | test-almocogratis`
    }
  },
  beforeRouteEnter (to, from, next) {
    if (store.getters['user/data']) next('/')
    else next()
  },
  data () {
    return {
      stateLogin: true,
      isLoading: false,
      email: undefined,
      username: undefined,
      password:  undefined,
      errors: []
    }
  },
  methods: {
    async register () {
      this.isLoading = true
      await this.$http.register({
          user: {
            email: this.email,
            username: this.username,
            password: this.password
          }
        }).then( ({ data }) => {
          this.setStore('user/data', data.user)
          this.setStore('user/jwtToken', data.user.token)
          this.$router.push({ name: 'Home' })
        }).catch(err => {
          this.errors = Object.entries(err.response.data.errors)
          this.stateLogin = false
        }).finally(() => {
          this.isLoading = false
          this.email = undefined
          this.username = undefined
          this.password = undefined
        })
    }
  }
}
</script>

<style>

</style>